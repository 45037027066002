import React from 'react';
import { connect } from 'react-redux';
import { DataTable, Pager, SearchInput, Button,  Tip } from 'tyb';


const { deleteMemberShip, exportMemberShip, exportAvailable } = iceStarkData.store.get('commonAction')?.memberManage;


const { loadCodedb } = iceStarkData.store.get('commonAction')?.codedb

// const { loadAddressBookById } = iceStarkData.store.get('commonAction')?.addressBooks;
import { formatDate } from 'ucode-utils';
import enums from '@/assets/enum.json';
import { handSortOrder } from 'ucode-utils';

import ViewDialog from './ViewDialog';
import './index.scss';


@connect(
  state => {
    return {
      loading: state.codedb.loadCodedbLoading,
      codedbList: state.codedb.codedbList,



      permissionIds: state.users.permissionIds||[],
      openIds: state.users.openIds,
      addressBookDetail: state.addressBooks.addressBookDetail,
      availableCount: state.memberManage.availableCount,
    };
  },
  {
    loadCodedb: loadCodedb.REQUEST,
    deleteMemberShip: deleteMemberShip.REQUEST,
    exportMemberShip: exportMemberShip.REQUEST,
    // loadAddressBookById: loadAddressBookById.REQUEST,
    exportAvailable: exportAvailable.REQUEST
  }
)
class codeBankList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createdTimeSort: null,
      updatedTimeSort: null,
      dataId: '',


      viewDialogStatus:false,
      viewObject: {},


      perPageCountList: [20, 50, 100],
      params: {
        applyStart: '',
        applyEnd: '',
        id: '',
        page: 0,
        size: 20,
        sort: 'createdTime,desc'
      },

      factoryColumns: [
        {
          key: 'name',
          dataIndex: 'name',
          title: '码库名称',
        },
        {
          key: 'host',
          dataIndex: 'host',
          title: '主机名',
        },
        
        {
          key: 'database',
          dataIndex: 'database',
          title: '库名',
        },
        {
          key: 'username',
          dataIndex: 'username',
          title: '用户名',
        },
        {
          key: 'mappingCnt',
          dataIndex: 'mappingCnt',
          title: '包含域名数',
        },
        // {
        //   key: 'updatedTime',
        //   dataIndex: 'updatedTime',
        //   title: '码类型',
        //   render: (value, record) => <div title={value ? formatDate(value) : ''}>
        //     <span className="text-overflow">{value ? formatDate(value) : ''}</span>
        //   </div>
        // },
        // {
        //   key: 'createdTime',
        //   dataIndex: 'createdTime',
        //   title: '码查询规则',
        //   render: (value, record) => <div title={value ? formatDate(value) : ''}>
        //     <span className="text-overflow">{value ? formatDate(value) : ''}</span>
        //   </div>

        // },
        // {
        //   key: 'jobStatus',
        //   dataIndex: 'jobStatus',
        //   title: '使用企业',
        //   render: (value, record) => <div title={value ? enums.memberTagJobStatus[value].name : ''}>
        //     <span className="text-overflow">{value ? enums.memberTagJobStatus[value].name : ''}</span>
        //   </div>

        // },
        {
          key: 'status',
          dataIndex: 'status',
          title: '操作',
          render: (value, record) => {
            return (
              <div className="table-operate-btn" style={{ color: '#006EFF' }}>
                 {this.props.permissionIds.includes('data.codedb.edit') && 
                <span onClick={() => { this.handleEdit(record) }}>编辑</span>
            }
                             {this.props.permissionIds.includes('data.codedb.view') && 
                <span onClick={() => { this.handleAllotOut(record) }}>域名</span>
              }
                {/* {this.props.permissionIds.includes('member-center.manage.membership.export') &&
                  <span onClick={() => { this.showExport(record.id) }}>导出</span>
                } */}

                {/* {this.props.permissionIds.includes('member-center.manage.membership.delete') &&
                  <span onClick={() => this.handleConfirm(record.id)}>删除
                  {this.state.visibleId == record.id && <ConfirmPopout
                      onCancel={this.onCancel}
                      onOk={this.onOk}
                      styles={{ right: '0px' }}
                      strongText={'确定删除该分组？'}
                      text={'分组删除后无法恢复，请谨慎操作'}
                    />}
                  </span>
                } */}
              </div>
            );
          },
        },
      ],
    
    };
  }


  changeSearchBox = (value) => {
    const params = this.state.params;
    params.name = value;
    this.setState({
      params
    })
  }

  search = () => {
    const params = this.state.params;
    params.page = 0;
    this.props.loadCodedb(params);
  }



  onPageChange = (val) => {
    const { params } = this.state;
    params.page = val;
    this.props.loadCodedb(params);
  }
  onPerPageChange = (val) => {
    const { params } = this.state;
    params.size = val;
    params.page = 0;
    this.props.loadCodedb(params);
  }



  // handleConfirm = (param) => {
  //   const { visibleId } = this.state;
  //   this.setState({ visibleId: param == visibleId ? '' : param })
  // }
  // onCancel = () => {
  //   this.handleConfirm(this.state.visibleId);
  // }

  // onOk = () => {
  //   const { visibleId } = this.state;
  //   this.handleConfirm(visibleId);
  //   this.props.deleteMemberShip({
  //     data: { id: visibleId },
  //     callback: () => {

  //       this.props.loadCodedb(this.state.params);
  //     }
  //   });
  // }

  // showExport = (id) => {
  //   this.props.exportAvailable({
  //     callback: () => {
  //       this.setState({ showExport: { show: true, id: id } });
  //     }
  //   });

  // }

  // onDialogOk = () => {
  //   const { showExport } = this.state;
  //   const { addressBookDetail } = this.props;
  //   this.props.exportMemberShip({
  //     data: {
  //       id: showExport.id,
  //       email: 'guanchen.xu@sao.so'
  //     },
  //     callback: () => {

  //       this.setState({ showExport: { show: false, id: '' } });
  //     }
  //   });
  // }



  componentDidMount() {
    const { loadCodedb, loadAddressBookById, openIds } = this.props;
    loadCodedb({});
    // loadAddressBookById({ id: openIds.addressBookId });
  }





  tableHeadFilter = param => {
    const { list, titleContent, value, filterFun } = param;
    return (
      <DataTable.FilterableColumnHeader
        list={list}
        value={value}
        onFilter={value => {
          filterFun(value);
        }}
      >
        {titleContent}
      </DataTable.FilterableColumnHeader>
    );
  };
  handleSort = (curOrder, key) => {
    const newOrder = handSortOrder(curOrder);
    if (key == 'createdTime') {
      this.setState({ createdTimeSort: newOrder, updatedTimeSort: null });
    }
    else {
      this.setState({ createdTimeSort: null, updatedTimeSort: newOrder });
    }


    const { params } = this.state;
    params.sort = `${key},${newOrder}`;
    this.props.loadCodedb(params);
  };
  // 排序 sort
  tableHeadSort = (col, type) => {
    return (
      <DataTable.SortableColumnHeader
        order={this.state[type]}
        sortKey={col.key}
        onSort={() => { this.handleSort(this.state[type], col.key); }}
      >
        {col.title}
      </DataTable.SortableColumnHeader>
    );
  };
  tableHeadInit = () => {
    return this.state.factoryColumns.map(column => {
      if (column.key === 'createdTime') {
        return {
          ...column,
          headerRender: col => this.tableHeadSort(col, 'createdTimeSort'),
        };
      }
      else if (column.key === 'updatedTime') {
        return {
          ...column,
          headerRender: col => this.tableHeadSort(col, 'updatedTimeSort'),
        };
      }

      // else if (column.key === 'factoryType') {
      //   return {
      //     ...column,
      //     headerRender: () => {
      //       const { loadCodedb, factoryPageListParam } = this.props;
      //       return this.tableHeadFilter({
      //         list: factoryTypeFilter,
      //         titleContent: '来源类型',
      //         value: factoryPageListParam[column.key] ? factoryPageListParam[column.key].split(',') : [],
      //         filterFun: value => {
      //           const newValue = value.join(',');
      //           loadCodedb({ factoryPageListParam: { ...factoryPageListParam, [column.key]: newValue, page: 0, }, });
      //         },
      //       });
      //     },
      //   };
      // } 
      else {
        return column;
      }
    });
  };

  handleAllotOut = (record) => {
    this.props.history.push(`/datacodedb/ip?name=${record.name}`);
  }

  handleCreate = () => {
    this.setState({
      viewDialogStatus: 1,
      viewObject: {},
    })
  }
  handleEdit = (record) => {
    this.setState({
      viewDialogStatus: 2,
      viewObject: record
    })
  }
  handleCancel = () => {
    this.setState({
      viewDialogStatus: false,
      viewObject: {},
    })
  }


  render() {
    const { perPageCountList, params, viewObject, viewDialogStatus, } = this.state;
    const { codedbList = [], addressBookDetail, loading } = this.props;
    return (
      <div className={`codeBank-container`}>

        {/* top-bar */}
        <div className="factory-topbar">
        {this.props.permissionIds.includes('data.codedb.add') && 
          <Button onClick={this.handleCreate}>新增码库</Button>
    }

          {/* <div className="factory-search-input"
            style={{ marginRight: '10px' }}
          >
            <SearchInput
              value={params.name}
              placeholder="输入码库名称搜索"
              onChange={this.changeSearchBox}
              style={{ width: 180 }}
              onSearch={this.search}
            />
          </div>
 */}

        </div>
        {/* 列表，分页 */}
        <DataTable
          columns={this.tableHeadInit()}
          data={codedbList.content}
          rowKey="id"
          rowClassName={(record) => record.status == 'DISABLE' ? 'table-gray-row' : ''}
          loading={loading}

          messageRender={({ data, error }) => {
            if (error != null) { return error.msg; }
            if (data == null || data.length === 0) {
              return (
                <div className="text-weak">暂无数据</div>
              );
            }
          }}
        />
        {/* {(codedbList.content ? codedbList.content.length > 0 : false) &&
          <Pager
            total={codedbList.totalElements}
            current={params.page}
            perPageCountList={perPageCountList}
            perPageCount={params.size}
            onPageChange={this.onPageChange}
            onPerPageChange={this.onPerPageChange}
          />} */}


        {viewDialogStatus && <ViewDialog visible={viewDialogStatus} viewObject={viewObject} handleCancel={this.handleCancel} params={params} />}
      </div>
    );
  }
}

export default codeBankList;
