import React from 'react';
import { reduxForm, FieldArray } from 'redux-form'
import queryString from 'query-string';
import { Dialog, Switch, MultipleSelect, Tip, FormGroupList, Radio, Input, DatePicker, DateTimePicker, Button, RadioButtonGroup, DropdownList } from 'tyb';
import { FormGroupField } from 'tyb'
import { formatDate } from 'ucode-utils';

import { connect } from 'react-redux';

const { loadCodedb, createCodedb, editCodedb } = iceStarkData.store.get('commonAction')?.codedb;



import { withRouter } from "react-router-dom";
const { notification } = iceStarkData.store.get('commonAction')?.ui;

import { trims } from 'ucode-utils';

import enums from '@/assets/enum.json'

const setting = (props) => {
    return (
        <p style={{ fontSize: '12px', marginBottom: 0 }}>{props.showValue}</p>
    )
}

@withRouter
@connect(
    (state) => ({

        ViewDialog: state.form.ViewDialog

    }),
    {
        loadCodedb: loadCodedb.REQUEST,
        createCodedb: createCodedb.REQUEST,
         editCodedb: editCodedb.REQUEST,

        notification: notification,
    }
)
class ViewDialog0 extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            checked: 1,
            nowDate: formatDate(new Date()),
        };
    }

    validateName = (value) => {
        var re = /^[^\u4e00-\u9fa5]+$/
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '码库名称不能为空';
        }
        else if (!re.test(value2.trim())) {
            return '码库名称不支持中文';
        }
        else if (value2.trim().length > 50) {
            return '码库名称最多50个字符';
        }
    }
    validateContacts = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '主机不能为空';
        }
        else if (value2.trim().length > 50) {
            return '主机最多50个字符';
        }
    }
    validatePhone = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '端口不能为空';
        }
        else if (value2.trim().length > 50) {
            return '端口最多50个字符';
        }
    }
    validateusername = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '用户名不能为空';
        }
        else if (value2.trim().length > 50) {
            return '用户名最多50个字符';
        }
    }

    validatepassword = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '密码不能为空';
        }
        else if (value2.trim().length > 50) {
            return '密码最多50个字符';
        }
    }
    validateadminDB = (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return 'adminDB不能为空';
        }
        else if (value2.trim().length > 50) {
            return 'adminDB最多50个字符';
        }
    }
    validatedatabase= (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '库名不能为空';
        }
        else if (value2.trim().length > 50) {
            return '库名最多50个字符';
        }
    }

    validateconnectTimeout= (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '连接超时时间不能为空';
        }
        else if (value2.trim().length > 50) {
            return '连接超时时间最多50个字符';
        }
    }
    validatemaxConnectionIdleTime= (value) => {
        let value2 = '';
        if (value || value == '0') {
            value2 = value + "";
        }
        if (value2 === '' || value2.trim().length == 0) {
            return '端最大空闲时间不能为空';
        }
        else if (value2.trim().length > 50) {
            return '端最大空闲时间最多50个字符';
        }
    }
    
    componentDidMount() {
        if (this.props.visible == 1) {
            this.props.initialize({sslEnabled:false,sslInvalidHostNameAllowed:false })     
        }
        if (this.props.visible == 2) {
            let olddata = this.props.viewObject
            this.props.initialize({ ...olddata })
        }
    }
    modalOk = (values) => {

        if (this.props.visible == 1) {
            let newdata = values;
            this.props.createCodedb(
                {
                    data: {codes:[trims(newdata)]},
                    callback: () => {
                        this.props.handleCancel();
                        this.props.loadCodedb(this.props.params)
                    }
                }
            )
        }
        else if (this.props.visible == 2) {
            let newdata = values;
            this.props.editCodedb(
                {
                    data: {codes:[trims(newdata)]},
                    callback: () => {
                        this.props.handleCancel();
                        this.props.loadCodedb(this.props.params)
                    }
                }
            )
        }
    }


    render() {
        const { visible, handleCancel, handleSubmit } = this.props;
        return (
            <div>
                {(visible == 1 || visible == 2) &&
                    <Dialog className='enterprisesModal'
                        title={visible == 1 ? '新增码库' : visible == 2 ? '编辑码库'  : ''}
                        visible={visible}
                        onCancel={handleCancel}
                        onOk={handleSubmit(this.modalOk)}
                        style={{ width: '520px' }}
                    >
                        {(visible == 1 || visible == 2) && <div>
                            <FormGroupList >
                                <FormGroupField
                                    name="name"
                                    label='码库名称'
                                    placeholder="码库名称"
                                    disabled={visible==2}
                                    validate={this.validateName}
                                    component={Input}
                                />

                                <FormGroupField
                                    name="host"
                                    label='主机'
                                    placeholder="主机"
                                    validate={this.validateContacts}
                                    component={Input}
                                />

                                <FormGroupField
                                    name="port"
                                    label='端口'
                                    placeholder="端口"
                                    validate={this.validatePhone}
                                    component={Input}

                                />
                                <FormGroupField
                                    name="username"
                                    label='用户名'
                                    placeholder="用户名"
                                    validate={this.validateusername}
                                    component={Input}
                                />
                                 <FormGroupField
                                    name="password"
                                    label='密码'
                                    placeholder="密码"
                                    validate={this.validatepassword}
                                    component={Input}
                                    type='password'
                                />
                                        <FormGroupField
                                    name="adminDataBase"
                                    label='adminDB'
                                    placeholder="adminDB"
                                    validate={this.validateadminDB}
                                    component={Input}
                                />
                                       <FormGroupField
                                    name="database"
                                    label='库名'
                                    placeholder="database"
                                    validate={this.validatedatabase}
                                    component={Input}
                                    
                                />
                                     <FormGroupField
                                    name="connectTimeout"
                                    label='连接超时时间'
                                    placeholder="连接超时时间"
                                    validate={this.validateconnectTimeout}
                                    component={Input}
                                    
                                />
                                   <FormGroupField
                                    name="maxConnectionIdleTime"
                                    label='端最大空闲时间'
                                    placeholder="端最大空闲时间"
                                    validate={this.validatemaxConnectionIdleTime}
                                    component={Input}
                                    
                                />
                                  <FormGroupField
                                    name="sslEnabled"
                                    label='使用ssl'
                                    placeholder="使用ssl"
                                    // validate={this.validateEmail}
                                    component={Switch}
                                    
                                />
                                      <FormGroupField
                                    name="sslInvalidHostNameAllowed"
                                    label='允许无效主机名'
                                    placeholder="允许无效主机名"
                                    // validate={this.validateEmail}
                                    component={Switch}
                                    
                                />
                            </FormGroupList >

                        </div>}

                     
                    </Dialog>}
            </div>
        )
    }
}



const ViewDialog = reduxForm({
    form: 'ViewDialog',
})(ViewDialog0);
export default ViewDialog;